import React from 'react';
import { Link, graphql } from 'gatsby';
import { window } from 'browser-monads';
import Layout from '../components/layout';
import Nav from '../components/nav';
import MobileNav from '../components/mobileNav';
import Card from '../components/card'
import Footer from '../components/footer';
import './archive.scss';

const DesignArchive = (props) => {

  const designContent = props.data.allContentfulDesign
  const { currentPage, numPages } = props.pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? '/design' : `/design/${currentPage - 1}`
  const nextPage = `/design/${currentPage + 1}`

  return (
    <Layout>
      <Nav />
      <MobileNav />

      <header>
        <div className='archive__section'>
          <div className='archive__hero'></div>
          <div className='archive__nav'>
            <Link to='/design' className={window.location.href.indexOf('/design') > 0 ? 'archive__nav--link selected' : 'archive__nav--link'}>All</Link>
            <Link to='design/print' className={window.location.href.indexOf('design/print') > 0 ? 'archive__nav--link selected' : 'archive__nav--link'}>Print</Link>
            <Link to='design/illustration' className={window.location.href.indexOf('design/illustration') > 0 ? 'archive__nav--link selected' : 'archive__nav--link'}>Illustration</Link>
            <Link to='design/ux-ui' className={window.location.href.indexOf('design/ux-ui') > 0 ? 'archive__nav--link selected' : 'archive__nav--link'}>UX/UI</Link>
            <Link to='design/branding' className={window.location.href.indexOf('design/branding') > 0 ? 'archive__nav--link selected' : 'archive__nav--link'}>Branding</Link>
          </div>
        </div>
      </header>

      <Card
        sectionData={designContent}
        sectionCategory="designCategory"
        sectionLink="/design"
        sectionArchive={true}
      />

      <div className='pagination'>
        <div className='pagination__item'>
          {!isFirst && (
            <Link to={prevPage} rel='prev'>
              <div className='arrow__back'></div>
            </Link>
          )}
        </div>
        <div className='pagination__item'>
          {!isLast && (
            <Link to={nextPage} rel='next'>
              <div className='arrow__next'></div>
            </Link>
          )}
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default DesignArchive

export const pageQuery = graphql` 
 query DesignArchiveQuery ($skip: Int!, $limit: Int!) {
   allContentfulDesign(
       sort: { fields: [createdAt], order: DESC }
       filter: {
       node_locale: {eq: "en-US",}}
       skip: $skip
       limit: $limit
     ) {
     edges {
       node {
         id
         slug
         title
         createdAt
         designCategory {
           name
           id
         }
         featuredImage {
           fluid(maxWidth: 1200, quality: 85) {
             src
             ...GatsbyContentfulFluid
           }
         }
       }
     }
   }
 }
`
